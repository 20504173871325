import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SpecificationStructure } from "../models/specification.model";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class SpecificationService {

  constructor(private http: HttpClient) { }

  getSpecificationStructure(): Observable<SpecificationStructure> {
    return this.http.get<SpecificationStructure>(`${environment.apiUrl}specificationstructure`);
  }

  getFamilies(): Observable<SpecificationStructure> {
    return this.http.get<SpecificationStructure>(environment.apiUrl + 'specificationstructure/compact');
  }


}  